import React from "react"
import tw from "twin.macro"
import Image from "./image"

const Section = tw.section`py-8 px-5 lg:px-8 lg:py-6`
const Title = tw.h3`p-0 m-0 font-bold mt-6 lg:mt-0`

export function LeadMagnet({ subtitle, image, title, href, actionText, alt }) {
  return (
    <Section>
      <div tw="max-w-screen-lg mx-auto">
        <div tw="flex flex-wrap lg:flex-nowrap">
          <Image
            src={image}
            alt={alt}
            tw="w-full lg:w-1/2 mr-6"
            objectFit="contain"
            imgStyle={{ margin: 0 }}
          />
          <div tw="text-center flex flex-col items-center w-full lg:w-1/2 justify-center ml-5">
            <Title style={{ fontSize: "24px", margin: 0 }}>{title}</Title>
            <p tw="text-base max-w-lg">{subtitle}</p>
            <a
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "bold",
              }}
              tw="block w-full lg:max-w-xs tracking-wider text-white leading-6 text-center py-4 px-2 rounded text-base shadow uppercase duration-100 ease-linear bg-secondary font-condensed font-bold hover:bg-secondary-hover hover:text-white hover:no-underline focus:text-white focus:no-underline"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {actionText}
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}
